/* Vendor files ------------------------------------ */

import aos from './partial/aos';
import change from './partial/header-change-on-scroll';

// Accordion
$('.accordion-trigger').click(function () {
	$(this).toggleClass('active');
	$(this).next().slideToggle(200).parent().toggleClass('is-active');
});

// Placeholder delete on focus
$(function () {
	$('input,textarea')
		.focus(function () {
			$(this).data('placeholder', $(this).attr('placeholder')).attr('placeholder', '');
		})
		.blur(function () {
			$(this).attr('placeholder', $(this).data('placeholder'));
		});
});

$('h1, h2, h3, h4, h5, p').each(function () {
	var tekst = $(this).html();
	tekst = tekst.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
	tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
	$(this).html(tekst);
});

var max = 200;
var tot, str;
$('.js-text-ellipsis').each(function () {
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

$('.js-text-ellipsis-100').each(function () {
	var max = 100;
	var tot, str;
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

$('.js-text-ellipsis-190').each(function () {
	var max = 190;
	var tot, str;
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

// menu horizontal scrolling
$('.sticky nav a').on('click', function () {
	$('.sticky nav a').removeClass('selected');
	$(this).addClass('selected');
	// CALL scrollCenter PLUSGIN
	$('.sticky nav').scrollCenter('.selected', 200);
});

jQuery.fn.scrollCenter = function (elem, speed) {
	var active = jQuery(this).find(elem); // find the active element
	var activeWidth = active.width() / 2; // get active width center
	var pos = active.position().left + activeWidth; //get left position of active li + center position
	var elpos = jQuery(this).scrollLeft(); // get current scroll position
	var elW = jQuery(this).width(); //get div width
	//var divwidth = jQuery(elem).width(); //get div width
	pos = pos + elpos - elW / 2 + 32 + 'px'; // for center position if you want adjust then change this

	jQuery(this).animate(
		{
			scrollLeft: pos,
		},
		speed == undefined ? 1000 : speed
	);
	return this;
};

$('sticky nav a.selected').trigger('click');

$(function () {
	$('.header__menu li').on('mouseenter mouseleave', function (e) {
		if ($('ul', this).length) {
			var elm = $('ul:first', this);
			var off = elm.offset();
			var l = off.left;
			var w = elm.width();
			var docH = $('.mainHeader').height();
			var docW = $('.mainHeader').width();

			var isEntirelyVisible = l + w <= docW;

			if (!isEntirelyVisible) {
				$(this).addClass('edge');
			} else {
				$(this).removeClass('edge');
			}
		}
	});
});

jQuery(function () {
	function setAspectRatio() {
		jQuery('iframe').each(function () {
			jQuery(this).css('height', (jQuery(this).width() * 10) / 16);
		});
	}

	setAspectRatio();
	jQuery(window).resize(setAspectRatio);
});
